.container {
  background-color: black;
  padding: 10px 16px;
  padding-top: 85px;
  position: fixed;
  width: 100%;
  z-index: 998;

  .timer {
    width: auto;
    background: #2b1b03;
    position: relative;
    border: 1px solid #383838;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    display: flex;
    padding: 0 10px;
    padding-left: 20px;
    height: 60px;
    flex-direction: row;

    .timerText {
      font-size: 12px;
      color: white;
      width: 180px;
      margin-left: 10px;
      margin-bottom: 0px;
    }

    .Qc_badge {
      position: absolute;
      left: -45px;
      height: 60px;
    }
  }
}

.section {
  height: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
}

.qs_banner_1 {
  height: 50px;
}

.appstore_banner {
  height: 50px;
}

.timeRenderer {
  display: flex;
  flex-direction: row;
}

.timeSpan {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 10%;
  background: linear-gradient(317.06deg, #ffbae6 3.35%, #fff2fa 100%);
  border: 1.2px solid #ff00a1;

  p {
    text-align: center;
    color: black;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 16px;
  }

  span {
    font-size: 10px;
  }
}

.profilePic {
  width: 50px;
  height: 50px;
}

.profileContainer {
  display: flex;
  flex-direction: row;

  .profileTextContainer {
    margin-left: 10px;
  }

  .inviterText {
    font-style: italic;
    color: white;
    margin-bottom: 0px;
  }

  .profileName {
    color: white;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

.platforms {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  width: auto;
  p {
    font-style: italic;
    font-weight: 500;
    color: white;
    margin-bottom: 0px;
  }
  .badgeContainer {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    .badges {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
      color: black;
      span {
        font-size: 10px;
        margin-top: 5px;
        color: white;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.mContainer {
  /* width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  background: linear-gradient(
    180deg,
    #077dfd 0%,
    #ffffff 19.53%,
    #f5faff 51.56%,
    #b6daff 100%
  );
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  flex-direction: column; */

  .hidePlatforms {
    width: 100%;
    height: 100px;
    animation: hide 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .showPlatforms {
    width: 100%;
    height: 100px;
    animation: show 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .timer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    background: transparent;
    margin: 0px 10px;

    .Qc_badge {
      height: 50px;
    }

    .timerText {
      font-size: 13px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .mTimeRenderer {
      display: flex;
      flex-direction: row;

      .mTimeSpan {
        border: 1.2px solid #ff00a1;
        border-radius: 4px;
        width: 50px;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: linear-gradient(317.06deg, #ffbae6 3.35%, #fff2fa 100%);

        p {
          margin-bottom: 0px;
          font-weight: bold;
        }
      }
    }
  }

  .mQS_banner {
    height: 50px;
  }

  .divider {
    height: 1px;
    width: 90%;
    background-color: #37373750;
    margin: 15px 0;
  }

  .timerDiv {
    display: flex;
    justify-content: space-around;
    margin: 0 15px;

    .timerText {
      font-size: 14px;
      color: black;
      width: auto;
      margin-left: 10px;
      margin-bottom: 0px;
    }

    .profileContainer {
      display: flex;
      flex-direction: row;
      border-left-color: #37373750;
      border-top-left-radius: 2px;
      padding-left: 10px;
      border-left-width: 1px;
      border-left-style: solid;
    }
    .mAppStoreBanner {
      height: 50px;
      margin-top: 10px;
    }
  }
  .platforms {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    width: 100%;
    p {
      font-style: italic;
      font-weight: 500;
      margin-bottom: 0px;
      color: black;
    }
    .badgeContainer {
      display: flex;
      flex-direction: row;
      .badges {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 10px;
        color: black;
        span {
          font-size: 10px;
          margin-top: 5px;
          color: black;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.qs_left_banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .qs_patent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #ffffff50;
    padding-right: 20px;

    p {
      margin-bottom: 0px;
      color: white;
      margin-left: 10px;
      font-weight: 500;
    }
  }

  .qs_risk_free {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #ffffff50;
    padding: 0px 20px;

    img {
      height: 50px;
    }

    .free_trial {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      p {
        margin-bottom: 0px;
        color: white;
        font-weight: 500;
      }

      span {
        font-style: italic;
        font-size: 12px;
        color: white;
      }
    }
  }
}

.spacer {
  height: 20px;
}

.mContainerHide {
  display: none;
}

@keyframes show {
  0% {
    display: none;
    opacity: 0;
    height: 0px;
  }
  100% {
    display: flex;
    opacity: 1;
    height: 100px;
  }
}

@keyframes hide {
  0% {
    display: flex;
    opacity: 1;
    height: 100px;
  }
  100% {
    display: none;
    opacity: 0;
    height: 0px;
  }
}

.black_banner_sticky {
  position: sticky;
  z-index: 1000;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
  0% {
    top: 0;
  }
  100% {
    top: -10%;
  }
}
