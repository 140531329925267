.referralModal {
  background-color: rgb(50, 116, 246);
  border-radius: 20px;
  width: 350px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 18px;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
    margin: 5px;
  }
}

.referrerBadge {
  background-color: #fff !important;
  text-align: center;
  padding: 8px 8px;
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    box-shadow: none !important;
    // margin-top: 20% !important;
  }

  .referrerHeading {
    font-size: var(--md-font);
    font-weight: 600;
  }

  .referralBadgeWrapper {
    margin: 0;

    img {
      margin: 0;
    }
  }

  .contactName {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto';
  }

  .refProfilePic {
    height: 120px;
  }
}

.modalContainer {
  width: 100%;
}

.modalImage {
  width: 100%;
  padding: 10px;
}

.modalImageContainer {
  background-color: rgb(35, 84, 180);
  width: 100%;
}

.referredByHeading {
  font-size: 1rem;
  font-weight: 600;
}

.referredByName {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Roboto';
}

.continueBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 40px;
    background: #077dfd;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    border: none;
    display: flex;
    align-items: center;
  }
}

.timerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px;

  .timeText {
    font-size: 12px;
    margin-left: 10px;
    color: #eaeaea;
    margin-bottom: 0px;

    @media only screen and (max-width: 330px) {
      font-size: 10px;
    }
  }

  .quickStarterBadge {
    height: 60px;
    margin-left: 5px;
  }
}

.timeRenderer {
  display: flex;
  flex-direction: row;
}

.timeSpan {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 10%;
  background: linear-gradient(317.06deg, #ffbae6 3.35%, #fff2fa 100%);
  border: 1.2px solid #ff00a1;

  p {
    text-align: center;
    color: black;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
  }

  span {
    font-size: 12px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.startNow {
  width: 100%;
  background-color: #bedeff;
  color: #0076ff;
  border-radius: 5px;
  height: 30px;
  border: none;

  font-weight: 500;
}

.view_referral_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  button {
    background-color: #BEDEFF;
    color: #0076ff;
    border-radius: 5px;
    height: 30px;
    width: 95%;
    font-weight: 500;
    border: none;
    outline: none;
  }
}