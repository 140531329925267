// .container {
//   padding-top: 100px;
//   padding-bottom: 100px;
//   padding-left: 5%;
//   padding-right: 5%;

//   @media screen and (max-width: 786px) {
//     padding-left: 3%;
//     padding-right: 3%;
//   }
//   h2 {
//     text-align: center;
//     font-weight: 700;
//     font-size: 40px;
//     margin-bottom: 50px;
//   }
//   .box {
//     display: flex;
//     gap: 20px;
//     background-color: #edf2f8;
//     border-radius: 10px;
//     padding: 15px;
//   }
//   .FirstBox {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     img {
//       width: 50px;
//     }
//   }
//   .boxTitle {
//     font-weight: 700;
//     margin-bottom: 8px;
//   }
// }


.container {
  font-family: 'Open Sans', sans-serif;
  padding: 50px 52px ;
  background-color: white;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.headerBanner {
  background-color: #0147a7;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 20px;
  border-radius: 78px;

  h2 {
    font-size: 32px;
    margin: 0;
    font-weight: 700;
  }

  h3 {
    font-size: 22px;
    margin: 5px 0 0 0;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    
    h2 {
      font-size: 24px;
    }
    
    h3 {
      font-size: 18px;
    }
  }
}

.headerContent {
  text-align: center;
  flex: 1;
}

.coinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;

  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
}

.coinImage {
  width: 100%;
  height: auto;
  max-width: 100px;
}

.featureBox {
  display: flex;
  gap: 24px;
  background-color: #EDF2F8;
  border-radius: 8px;
  padding: 12px;
  height: 130px;
  width: auto;
  border-radius: 5px;
  
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  ul {
    margin: 0;
    padding-left: 20px;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 5px;
  padding: 10px;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
}

.customIconWrapper {
  width: 85px; // Custom width
  height: auto; // Custom height

  @media screen and (max-width: 500px) {
    width: 60px;
    height: auto;
  }
}

.contentWrapper {
  flex: 1;
  font-size: 15px;
  line-height: 100%;
  font-weight: 400;
  gap: 8px;
  line-height: 1.4;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.coinsStack {
  position: relative;
  width: 60px;
  height: 60px;
  
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .stackedCoin {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}