.container {
  padding: 4rem;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #0065d3;

  @media screen and (max-width: 786px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  h2 {
    text-align: center;
    color: white;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 40px;
  }
  .upperBox {
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
  }
  h4 {
    font-size: 25px;
    line-height: 1.4;
  }
  .boxItem {
    color: white;
    padding: 15px;
    display: flex;
    gap: 20px;
    background-color: #00000017;
    border-radius: 10px;
    font-size: 15px;
    line-height: 1.4;
    @media screen and (max-width: 640px) {
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    }
  }
  .boxImage {
    border-radius: 10px;
    width: 150px;
    height: 150px;
  }
  hr {
    margin-bottom: 2rem;
  }

  ul{
    line-height: 1.4;

    li{
      font-size: 15px;
    }

    @media screen and (max-width: 640px) {
  
      li {
       font-size: 10px;
      }
    }
  }

  .iconsRow {
    display: flex;
    flex-direction: row !important;
    justify-content: left;
  
    img {
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
    }
  
    @media screen and (max-width: 640px) {
  
      img {
        margin-bottom: 0.5rem;
        margin-right: 0.2rem;
      }
    }
  }


  /* ul {
    line-height: 1.5em;
    margin: 5px 0 15px;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding: 0 0 0 20px;
      margin: 12px 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        background-color: #0076ff;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
  } */
}
